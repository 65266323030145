/* line 18, scss/80-themes/Saunders/60-components/scrollloader.scss */
.c-loading {
	position: relative;
	width: 100%;
	text-align: center;
}

/* line 23, scss/80-themes/Saunders/60-components/scrollloader.scss */
.c-loading-container {
	opacity: 0;
	position: absolute;
}

/* line 28, scss/80-themes/Saunders/60-components/scrollloader.scss */
.c-loading:before {
	left: 0;
	border: 1px dotted rgb(var(--color-global-border));
	content: '';
	position: absolute;
	top: 50%;
	width: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-loading:before {
	left: auto;
	right: 0;
}

/* line 38, scss/80-themes/Saunders/60-components/scrollloader.scss */
.c-loading__button-wrapper {
	position: relative;
	padding: 0 10px;
	background: rgb(var(--color-global-body));
	display: inline-block;
}

/* line 44, scss/80-themes/Saunders/60-components/scrollloader.scss */
.c-loading__button-wrapper .o-button {
	text-transform: capitalize;
	color: rgb(var(--color-global-text));
	background-color: rgb(var(--color-global-title));
	border-color: rgb(var(--color-global-border));
	cursor: default;
}

/* line 51, scss/80-themes/Saunders/60-components/scrollloader.scss */
.c-loading__button-wrapper .o-button:hover {
	color: rgb(var(--color-global-text));
	background-color: rgb(var(--color-global-title));
	border-color: rgb(var(--color-global-border));
}

/*# sourceMappingURL=../../../../true */